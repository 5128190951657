/*********************************/
/*             Contact           */
/*===============================*/
.form-input {
    @apply w-full py-2 px-4 border border-inherit rounded-xl h-11 outline-none bg-transparent bg-white text-sm shadow-none text-black font-medium;
}

/* Validation */
.error {
    @apply my-2 mx-0 hidden text-orange-600;
}
  
#ajaxsuccess {
    @apply text-base w-full hidden clear-both my-2 mx-0;
}

.error_message,
#success_page {
    @apply p-2.5 mb-5 text-center rounded-md;
}

.error_message {
    @apply bg-orange-600/5 text-orange-600;
}

.contact-loader {
    @apply hidden;
}
  
#success_page {
    @apply bg-emerald-600/5 text-emerald-600;
}
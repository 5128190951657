/*********************************/
/*             General           */
/*===============================*/
html {
    @apply relative scroll-smooth;
}

body {
    @apply font-nunito text-[15px] text-black;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    @apply font-nunito leading-normal font-semibold;
}

p {
    @apply leading-relaxed;
    @apply font-nunito;
}

::selection {
    @apply bg-indigo-600/90 text-white;
}

// zoyothemes boxlayout demo
.zThemes-layout {
    @apply bg-[url('../images/bg-img.jpg')] bg-fixed bg-repeat;
    .zThemesbox {
        @apply bg-white max-w-[1170px] shadow m-0 mx-auto;
    }
    .boxed-home {
        @apply max-w-[1170px] m-0 mx-auto;
    }
}
/* 
Template Name: Queue - Tailwind CSS Landing Page Template
Version: 1.0.0
Author: Zoyothemes
Email: zoyothemes@gmail.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/*********************************/
/*             General           */
/*===============================*/
html {
  @apply relative scroll-smooth;
}

body {
  @apply font-nunito text-[15px] text-black;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @apply font-nunito leading-normal font-semibold;
}

p {
  @apply leading-relaxed;
  @apply font-nunito;
}

::selection {
  @apply bg-indigo-600/90 text-white;
}

.zThemes-layout {
  @apply bg-[url("../images/bg-img.jpg")] bg-fixed bg-repeat;
}
.zThemes-layout .zThemesbox {
  @apply bg-white max-w-[1170px] shadow m-0 mx-auto;
}
.zThemes-layout .boxed-home {
  @apply max-w-[1170px] m-0 mx-auto;
}

/********************/
/*     Buttons      */
/*==================*/
.btn {
  @apply py-2 px-8 inline-block font-semibold tracking-wide border align-middle transition-all duration-500 ease-in-out text-base text-center;
  /*  Button icons */
  /* Button Link */
}
.btn.btn-lg {
  @apply py-2.5 px-6 text-lg;
}
.btn.btn-sm {
  @apply py-[5px] px-4 text-sm;
}
.btn.btn-icon {
  @apply p-0 h-9 w-9 inline-flex items-center text-center justify-center text-base;
}
.btn.btn-icon.btn-lg {
  @apply h-12 w-12;
}
.btn.btn-icon.btn-sm {
  @apply h-8 w-8;
}
.btn.btn-link {
  @apply relative p-0 border-none after:content-[""] after:absolute after:h-px after:w-0 after:right-0 after:bottom-0 after:left-0 after:transition-all after:duration-500;
}
.btn.btn-link:hover {
  @apply after:w-full after:right-auto;
}

/*********************************/
/*             Menu              */
/*===============================*/
.navbar-custom {
  @apply fixed top-0 start-0 end-0 z-999 bg-transparent py-5 transition-all duration-500 font-nunito bg-white lg:bg-transparent;
}
.navbar-custom .navigation {
  @apply w-full m-0;
}
.navbar-custom .navigation .navbar-nav {
  @apply flex flex-col lg:flex-row;
}
.navbar-custom .navigation .navbar-nav .nav-link {
  @apply block py-2 px-4 text-dark uppercase font-bold text-sm tracking-wide hover:text-blue-500;
}
.navbar-custom .navigation .navbar-nav .active .nav-link {
  @apply text-blue-500;
}
.navbar-custom .nav-light .navbar-nav .nav-link {
  @apply text-white !important;
}
.navbar-custom .nav-light .navbar-nav .active .nav-link {
  @apply text-blue-500 !important;
}
.navbar-custom .navbar-brand {
  @apply me-12 p-0 text-dark font-bold text-2xl;
}
.navbar-custom .menu-social .login-btn-primary {
  @apply hidden;
}
.navbar-custom .menu-social .login-btn-light {
  @apply inline-block;
}
.navbar-custom.is-sticky {
  @apply bg-white shadow;
}
.navbar-custom.is-sticky .navbar-brand .l-dark {
  @apply inline-block;
}
.navbar-custom.is-sticky .navbar-brand .l-light {
  @apply hidden;
}
.navbar-custom.is-sticky .menu-social .login-btn-primary {
  @apply inline-block;
}
.navbar-custom.is-sticky .menu-social .login-btn-light {
  @apply hidden;
}
.navbar-custom.is-sticky .logo {
  @apply text-dark !important;
}
.navbar-custom.is-sticky .nav-light .nav-item .nav-link {
  @apply text-dark !important;
}
.navbar-custom.is-sticky .nav-light .active .nav-link {
  @apply text-blue-500 !important;
}
.navbar-custom.is-sticky.navbar-custom {
  @apply shadow-[0px_10px_33px_rgba(0,0,0,0.1)];
}
.navbar-custom .logo {
  @apply text-white !important;
}

/* // _footer.scss */
/*********************************/
/*         Footer                */
/*===============================*/
.footer .foot-subscribe input::placeholder {
  @apply text-gray-200;
}

/*********************************/
/*             Helper            */
/*===============================*/
.container,
.contacinr-fluid {
  @apply relative;
}

.text-type-element {
  @apply z-2 after:content-[""] after:absolute after:h-1 after:w-auto after:bg-orange-300 after:right-0 after:bottom-0 after:left-0 after:z-0 after:rounded-md after:transition-all after:duration-300 after:ease-in-out;
}

/*****************/
/* Tobii Lighbox */
/*****************/
.tobii__btn svg {
  height: theme("height.5");
  width: auto;
}

.tobii__counter {
  font-size: theme("fontSize.base");
}

.tobii-zoom {
  display: block;
}

.tobii-zoom__icon {
  display: none;
}

#grid {
  padding: theme("spacing.0");
}

/*****************/
/* Subscribe Form */
/*****************/
.subcribe-form form {
  @apply max-w-xl;
}
.subcribe-form form input {
  @apply py-4 pr-40 pl-8 w-full h-11 outline-none text-dark;
}
.subcribe-form form .btn {
  @apply absolute top-0 right-0 h-11;
}

/*****************/
/*    Shapes     */
/*****************/
/* Shapes */
.shape {
  @apply absolute right-0 -bottom-px left-0;
}
.shape > svg {
  @apply w-full h-auto;
  transform: scale(2);
  transform-origin: theme("transformOrigin.top") theme("transformOrigin.center");
}

/*****************/
/*  back-to-top  */
/*****************/
.back-to-top {
  @apply hidden;
}

/*****************/
/*  Bg Video Hero  */
/*****************/
.bg-video-wrapper {
  @apply absolute top-0 left-0 w-full h-full z-0 pointer-events-none overflow-hidden;
}
.bg-video-wrapper iframe {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw];
}

/*****************/
/*   Preloader   */
/*****************/
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  @apply fixed inset-0;
}
#preloader #status {
  @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
}
#preloader #status .spinner {
  @apply w-10 h-10 relative my-[100px] mx-auto;
}
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  @apply w-full h-full rounded-full bg-blue-600/60 absolute top-0 left-0;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.filter-options li a {
  @apply block text-black px-4 tracking-[0.8px] leading-[34px] font-bold my-1 mx-1 border border-gray-300 text-sm rounded-xl transition-all duration-500;
}

.filter-options li:hover a,
.filter-options li.active a,
.filter-options li:active a,
.filter-options li:focus a {
  @apply text-primary border-primary;
}

/*********************************/
/*             Contact           */
/*===============================*/
.form-input {
  @apply w-full py-2 px-4 border border-inherit rounded-xl h-11 outline-none bg-transparent bg-white text-sm shadow-none text-black font-medium;
}

/* Validation */
.error {
  @apply my-2 mx-0 hidden text-orange-600;
}

#ajaxsuccess {
  @apply text-base w-full hidden clear-both my-2 mx-0;
}

.error_message,
#success_page {
  @apply p-2.5 mb-5 text-center rounded-md;
}

.error_message {
  @apply bg-orange-600/5 text-orange-600;
}

.contact-loader {
  @apply hidden;
}

#success_page {
  @apply bg-emerald-600/5 text-emerald-600;
}

/*****************/
/*  Testimonial  */
/*****************/
.tns-nav {
  text-align: center;
}

.tns-nav button {
  transition: all 0.5s ease;
  background: rgba(0, 123, 255, 0.7);
  border-radius: 3px;
  width: 10px;
  height: 5px;
  margin: 5px;
}

.tns-nav button.tns-nav-active {
  @apply bg-primary !important;
}
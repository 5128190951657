//
// Gallery Demo
//

.filter-options li a{
    @apply block text-black px-4 tracking-[0.8px] leading-[34px] font-bold my-1 mx-1 border border-gray-300 text-sm rounded-xl transition-all duration-500
}

.filter-options li:hover a,
.filter-options li.active a,
.filter-options li:active a,
.filter-options li:focus a {
    @apply text-primary border-primary;
}
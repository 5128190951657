/*****************/
/*  Testimonial  */
/*****************/
.tns-nav {
    text-align: center;
  }
.tns-nav button {
    transition: all 0.5s ease;
    background: rgba(0, 123, 255, 0.7);
    border-radius: 3px;
    width: 10px;
    height: 5px;
    margin: 5px;
} 
.tns-nav button.tns-nav-active {
    @apply bg-primary #{!important};
}

/*********************************/
/*             Helper            */
/*===============================*/
.container,
.contacinr-fluid {
    @apply relative;
}

.text-type-element {
    @apply z-2 after:content-[''] after:absolute after:h-1 after:w-auto after:bg-orange-300 after:right-0 after:bottom-0 after:left-0 after:z-0 after:rounded-md after:transition-all after:duration-300 after:ease-in-out;
}

/*****************/
/* Tobii Lighbox */
/*****************/
.tobii__btn svg {
    height: theme('height.5');
    width: auto;
}

.tobii__counter {
    font-size: theme('fontSize.base');
}

.tobii-zoom {
    display: block;
}

.tobii-zoom__icon {
    display: none;
}

#grid {
    padding: theme('spacing.0');
}

/*****************/
/* Subscribe Form */
/*****************/
.subcribe-form {
    form {
        @apply max-w-xl;
        input {
            @apply py-4 pr-40 pl-8 w-full h-11 outline-none text-dark;
        }

        .btn {
            @apply absolute top-0 right-0 h-11;
        }
    }
}

/*****************/
/*    Shapes     */
/*****************/
/* Shapes */
.shape {
    @apply absolute right-0 -bottom-px left-0;
    &>svg {
        @apply w-full h-auto;
        transform: scale(2);
        transform-origin: theme('transformOrigin.top') theme('transformOrigin.center');
    }
}

/*****************/
/*  back-to-top  */
/*****************/
.back-to-top {
    @apply hidden;
}

/*****************/
/*  Bg Video Hero  */
/*****************/
.bg-video-wrapper {
    @apply absolute top-0 left-0 w-full h-full z-0 pointer-events-none overflow-hidden;
    iframe {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw];
    }
}

/*****************/
/*   Preloader   */
/*****************/
#preloader {
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
    z-index: 99999;
    @apply fixed inset-0;
    #status {
        @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
        .spinner {
            @apply w-10 h-10 relative my-[100px] mx-auto;
            .double-bounce1, .double-bounce2 {
                @apply w-full h-full rounded-full bg-blue-600/60 absolute top-0 left-0;
                animation: sk-bounce 2.0s infinite ease-in-out;
            }
            .double-bounce2 {
                animation-delay: -1.0s;
            }
        }
    }
}

@keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
    }
}